import {EnrichedItem, ListItem, MailBestFeedArticle, ManualFeedArticle, TopicFeedArticle} from './types';

const DAY_MS = 24 * 60 * 60 * 1000;
const LIVE_DAYS_MS = DAY_MS;
const VALID_DAYS_MS = 14 * DAY_MS;

const isArticleLive = (timestamp: string | null): boolean => {
  if (!timestamp) return false;

  const liveDate = new Date(timestamp);

  const diffMs = Date.now() - liveDate.getTime();

  return diffMs <= LIVE_DAYS_MS;
};

const isArticleRecent = (timestamp: string): boolean => {
  const liveDate = new Date(timestamp);

  const diffMs = Date.now() - liveDate.getTime();

  return diffMs <= VALID_DAYS_MS;
};

export const sortByForcedItems = (items: ListItem[], filter?: 'forceOnly'): ListItem[] => {
  const forceList: ListItem[] = [];
  const otherList: ListItem[] = [];
  const ids: Record<string, boolean> = {};

  items.forEach((item) => {
    if (ids[item.destination]) {
      return;
    }
    ids[item.destination] = true;

    if (item.force) {
      forceList.push(item);
    } else if (!filter) {
      otherList.push(item);
    }
  });

  return [...forceList, ...otherList];
};

export const convertManualItems = (
  items: ManualFeedArticle[],
  itemList: ListItem[],
  ico: string,
  mobile: boolean
): EnrichedItem[] => {
  const itemMap = itemList.reduce((acc: Record<string, ListItem>, item) => {
    if (item.destination) {
      acc[item.destination] = item;
    }

    return acc;
  }, {});

  const updatedItems = items.map((article) => {
    const {articleId} = article;
    const item = itemMap[articleId] ?? null;

    const heading = (mobile ? item?.mobileHeadline : item?.desktopHeadline) || item?.heading || article.headline;

    if (!heading?.trim()) {
      return null;
    }

    const imageUrl = item?.imageUrl || item?.image?.croppedImage?.src || article.puffThumbUrl || '';
    const subheading = item?.subheading || article.previewText || '';

    return {
      articleId,
      commentsCount: article.commentsCount ?? 0,
      heading: heading.trim(),
      imageUrl,
      isLive: isArticleLive(article.showAsLiveDate ?? null),
      shareCount: article.shareCount ?? 0,
      shareImageUrl: article.shareImageUrl ?? '',
      shortURL: article.shortURL ?? '',
      socialHeadline: article.socialHeadline ?? '',
      subheading,
      url: `${article.articleURL}?ico=${ico}`
    };
  });

  return asEnrichedItemList(updatedItems, {});
};

export const convertTopicItems = (
  items: TopicFeedArticle[],
  ico: string,
  excludedIds: Record<number, boolean>,
  isArticleRead: ((id: number) => boolean) | null
): EnrichedItem[] => {
  const updatedItems = items.map((article) => {
    const heading = article.headline;

    if (!heading || !isArticleRecent(article.firstPubDate)) {
      return null;
    }

    if (isArticleRead && isArticleRead(article.articleId)) {
      return null;
    }

    const shareCount = article.fbShareCount + article.piShareCount;

    return {
      articleId: article.articleId,
      commentsCount: article.commentsCount,
      heading,
      imageUrl: article.imageLargePreview,
      isLive: isArticleLive(article.showAsLiveDate),
      shareCount,
      shareImageUrl: article.imageLargeDouble,
      shortURL: article.shortURL,
      socialHeadline: article.socialHeadline,
      subheading: article.previewText,
      url: `${article.articleURL}?ico=${ico}`
    };
  });

  return asEnrichedItemList(updatedItems, excludedIds);
};

export const convertMailbestItems = (
  items: MailBestFeedArticle[],
  ico: string,
  excludedIds: Record<number, boolean>,
  isArticleRead: ((id: number) => boolean) | null
): EnrichedItem[] => {
  const updatedItems = items.map((article) => {
    const heading = article.headline;

    if (!heading || !isArticleRecent(article.firstPubDate)) {
      return null;
    }

    if (isArticleRead && isArticleRead(article.articleId)) {
      return null;
    }

    return {
      articleId: article.articleId,
      commentsCount: article.commentsCount,
      heading,
      imageUrl: article.imgUrl ?? '',
      isLive: isArticleLive(article.showAsLiveDate ?? null),
      shareCount: article.shareCount,
      shareImageUrl: article.imgUrl ?? '',
      shortURL: article.shortURL ?? '',
      socialHeadline: article.title,
      subheading: article.subtitle,
      url: `${article.url}?ico=${ico}`
    };
  });

  return asEnrichedItemList(updatedItems, excludedIds);
};

type MaybeEnrichedItem = EnrichedItem | null;

const asEnrichedItemList = (items: MaybeEnrichedItem[], excludedIds: Record<number, boolean>): EnrichedItem[] => {
  const updatedItems: EnrichedItem[] = [];
  const ids: Record<string, boolean> = {};

  items.forEach((item) => {
    if (item && !excludedIds[item.articleId] && !ids[item.articleId]) {
      updatedItems.push(item);
      ids[item.articleId] = true;
    }
  });

  return updatedItems;
};
